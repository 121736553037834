import React from 'react';
import './AppLogo.scss';
import logo from '../logo.svg';

export default class AppLogo extends React.PureComponent {
  render(): React.ReactElement {
    return (
      <div className="app-logo">
        <img src={logo} alt="Ultima Labs logo" />
      </div>
    );
  }
}