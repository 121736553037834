import React from 'react';
import {RouteID} from "./Routes";
import {ExternalLink, InternalLink, LinkTarget} from '../framework/Links';
import './AppFooter.scss';

export const AppFooterIntro: React.FC = (props) => {
  return (
      <div className="app-footer-intro">{props.children}</div>
  );
}

export const AppFooterColumn: React.FC = (props) => {
  return (
      <div className="app-footer-column">{props.children}</div>
  );
}

export const AppFooterOutro: React.FC = (props) => {
  return (
      <div className="app-footer-outro">{props.children}</div>
  );
}

export class AppFooterSettings {
  isSticky: boolean = false;
}

export class AppFooterProps {
}

export interface AppFooterState {
  settings: AppFooterSettings
}

export class AppFooter extends React.Component<AppFooterProps, AppFooterState> {
  private static readonly SETTING_NAME: string = 'app-footer';

  componentDidMount(): void {
    let settings = new AppFooterSettings();
    try {
      const existing = window.localStorage.getItem(AppFooter.SETTING_NAME );
      if (existing) {
        settings = JSON.parse(existing);
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ settings: settings })
  }

  componentDidUpdate(prevProps: Readonly<AppFooterProps>, prevState: Readonly<AppFooterState>, snapshot?: any): void {
    if (this.state.settings) {
      window.localStorage.setItem(AppFooter.SETTING_NAME, JSON.stringify(this.state.settings));
    }
  }

  render(): JSX.Element {
    return (
        <footer className="app-footer">
          <div className="flex-max display-flex-row-wrap app-footer-columns">
            <div className="flex-auto" />
            <div className="flex-auto text-align-center">
              <div className="display-inline-block text-align-left">
                <p><InternalLink routeID={RouteID.ROUTE_ID_HOME} /></p>
                <p><InternalLink routeID={RouteID.ROUTE_ID_ENGINEERING} /></p>
                <p><InternalLink routeID={RouteID.ROUTE_ID_IMPROVEMENT} /></p>
                <p><InternalLink routeID={RouteID.ROUTE_ID_CONSULTANCY} /></p>
                <p><InternalLink routeID={RouteID.ROUTE_ID_RECRUITMENT} /></p>
                <p><InternalLink routeID={RouteID.ROUTE_ID_CONTACT} /></p>
              </div>
            </div>
            <div className="flex-auto text-align-center">
              <div className="display-inline-block text-align-left">
                <p>Follow us online:</p>
                <p>Twitter: <ExternalLink href="https://twitter.com/ultimalabs" target={LinkTarget.Blank}>@ultimalabs</ExternalLink></p>
                <p>Facebook: <ExternalLink href="https://www.facebook.com/ultimalabs" target={LinkTarget.Blank}>@ultimalabs</ExternalLink></p>
                <p>Instagram: <ExternalLink href="https://www.instagram.com/ultimalabs" target={LinkTarget.Blank}>@ultimalabs</ExternalLink></p>
                <p>LinkedIn: <ExternalLink href="https://www.linkedin.com/company/ultima-labs" target={LinkTarget.Blank}>ultima-labs</ExternalLink></p>
                <p>GitHub: <ExternalLink href="https://github.com/ultima-labs" target={LinkTarget.Blank}>ultima-labs</ExternalLink></p>
              </div>
            </div>
            <div className="flex-auto" />
          </div>
          <div className="flex-max display-flex-row-wrap app-footer-outro">
            <span className="flex-min"><strong>ABN</strong> 87 616 389 550</span>
            <span className="flex-min"><strong>ACN</strong> 616 389 550</span>
            <span className="flex-auto" />
            <span className="flex-min"><InternalLink routeID={RouteID.ROUTE_ID_TERMS}/> and <InternalLink routeID={RouteID.ROUTE_ID_PRIVACY}/></span>
            <span className="flex-auto" />
            <span className="flex-min">Copyright &copy; Ultima Labs Pty Ltd, 2016-{new Date().getFullYear()}.</span>
            <span className="flex-min">All Rights Reserved.</span>
          </div>
        </footer>
    );
  }
}

export default AppFooter;