import React from 'react';
import { Link } from 'react-router-dom';
import Routes, { RouteID } from '../application/Routes';

export enum LinkTarget {
  Self = 1,
  Blank = 2,
  Parent = 3,
  Top = 4
}

export interface GenericLinkProps {
  href: string;
  type?: 'primary'
      |'secondary'
      |'tertiary'
      |'primary button'
      |'secondary button'
      |'tertiary button'
      |'primary accent'
      |'secondary accent'
      |'tertiary accent'
      |'primary accent button'
      |'secondary accent button'
      |'tertiary accent button';
  target?: LinkTarget | null;
  isNoFollow?: boolean;
  isNoOpener?: boolean;
  isNoReferrer?: boolean;
  isDownload?: boolean;
}

export interface SecureLinkProps extends GenericLinkProps {
    isExternal?: boolean;
}

export default class SecureLink extends React.PureComponent<SecureLinkProps> {  
  render() {
    const href: string = typeof this.props.href === "number" ? Routes[this.props.href as RouteID].path : this.props.href as string;
    const className = this.props.type;
    const rels = [];
    
    if (this.props.isNoFollow) {
      rels.push("nofollow");
    }

    if (this.props.isExternal) {
      rels.push("external");
    }

    if (this.props.isNoOpener) {
      rels.push("noopener");
    }

    if (this.props.isNoReferrer) {
      rels.push("noreferrer");
    }

    let target = "";

    switch (this.props.target)
    {
      case LinkTarget.Self:
        if (!rels.includes("noreferrer")) {
          rels.push("noreferrer");
        }

        target = "_self";
        break;
        
      case LinkTarget.Blank:
        if (!rels.includes("noreferrer")) {
          rels.push("noreferrer");
        }

        if (!rels.includes("noopener")) {
          rels.push("noopener");
        }

        target = "_blank";
        break;

      case LinkTarget.Parent:
        if (!rels.includes("noreferrer")) {
          rels.push("noreferrer");
        }

        target = "_parent";
        break;
        
      case LinkTarget.Top:
        if (!rels.includes("noreferrer")) {
          rels.push("noreferrer");
        }

        target = "_top";
        break;

      default:
        if (rels.length) {
          return this.props.isExternal || this.props.isDownload ? (
            <a href={href} className={className} rel={rels.join(" ")} download={this.props.isDownload}>
              {this.props.children}
            </a>
          ) : (
            <Link to={href} className={className} rel={rels.join(" ")}>
              {this.props.children}
            </Link>
          );
        }

        return this.props.isExternal || this.props.isDownload ? (
          <a href={href} className={className} download={this.props.isDownload}>
            {this.props.children}
          </a>
        ) : (
          <Link to={href} className={className}>
            {this.props.children}
          </Link>
        );
    };   
    
    return this.props.isExternal || this.props.isDownload ? (
      <a href={href} className={className} target={target} rel={rels.join(" ")} download={this.props.isDownload}>
        {this.props.children}
      </a>
    ) : (
      <Link to={href} className={className} target={target} rel={rels.join(" ")}>
        {this.props.children}
      </Link>
    );
  }
}

export interface InternalLinkProps {
  routeID: RouteID;
  type?: 'primary'
      |'secondary'
      |'tertiary'
      |'primary button'
      |'secondary button'
      |'tertiary button'
      |'primary accent'
      |'secondary accent'
      |'tertiary accent'
      |'primary accent button'
      |'secondary accent button'
      |'tertiary accent button';
  target?: LinkTarget | null;
}

export class InternalLink extends React.PureComponent<InternalLinkProps, any> {
  componentDidMount() {
    this.validateRouteID();
  }

  componentDidUpdate() {
    this.validateRouteID();    
  }

  render() {
    return (
      <SecureLink href={Routes[this.props.routeID].path} 
                  type={this.props.type}
                  target={this.props.target}
                  isExternal={false}>
        {this.props.children ? this.props.children : Routes[this.props.routeID].linkTitle}
      </SecureLink>
    )
  }

  validateRouteID() {
    if (!Object.keys(Routes).includes(this.props.routeID.toString())) {
      console.error('Unknown route ID [%s] is passed to the InternalLink', this.props.routeID);
    }
  }
}

export interface ExternalLinkProps extends GenericLinkProps {
}

export class ExternalLink extends React.PureComponent<ExternalLinkProps, any> {
  render() {
    return (
      <SecureLink isExternal={true} {... this.props}>
        {this.props.children}
      </SecureLink>
    )
  }
}