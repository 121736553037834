import React from "react";
import "../framework/site.scss";

export default class LoadingContent extends React.PureComponent {
  render() {
    return (
      <main>
          <section className="loading">
              <div>
                  Loading...
              </div>
          </section>
      </main>
    );
  }
}