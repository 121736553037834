import React from 'react';
import Routes, { RouteID } from './Routes';
import { Link } from 'react-router-dom';
import './AppMenu.scss';

export class AppMenuItemProps {
  routeID?: RouteID
}

export class AppMenuItemState {
}

export class AppMenuItem extends React.PureComponent<AppMenuItemProps, AppMenuItemState> {
  render() {
    if (this.props.routeID) {
      const id = this.props.routeID;
      const definition = Routes[id];
      // const children = Object.values(Routes).filter((x) => x.parents && x.parents.includes(id));
      return (
        <div className="app-menu-item">
          <Link to={definition.path}>{definition.linkTitle}</Link>
        </div>
      );
    } else {
      return (
        <div className="app-menu-item">
          {this.props.children}
        </div>
      );
    }
  }
}

export class AppMenuProps {
}

export class AppMenuState {
}

export class AppMenuDesktopProps extends AppMenuProps {
}

export class AppMenuDesktopState extends AppMenuState {
}

export class AppMenuDesktop extends React.Component<AppMenuDesktopProps, AppMenuDesktopState> {
  render() {
    const Items = Object
      .keys(Routes)
      .map(x => parseInt(x))
      .filter(x => !Routes[x].parents)
      .filter(x => Routes[x].isMainMenu)
      .map(x => <AppMenuItem key={x} routeID={x}/>);
    return (
      <div className="app-menu-desktop">
          {Items}
      </div>
    );
  }
}

export class AppMenuTabletProps extends AppMenuProps {
}

export class AppMenuTabletState extends AppMenuState {
}

export class AppMenuTablet extends React.Component<AppMenuTabletProps, AppMenuTabletState> {
  render() {
    const Items = Object
      .keys(Routes)
      .map(x => parseInt(x))
      .filter(x => !Routes[x].parents)
      .filter(x => Routes[x].isMainMenu)
      .map(x => <AppMenuItem key={x} routeID={x}/>);
    return (
      <div className="app-menu-tablet">
          {Items}
      </div>
    );
  }
}

export class AppMenuMobileProps extends AppMenuProps {
}

export class AppMenuMobileState extends AppMenuState {
  isExpanded: boolean = false
}

export class AppMenuMobile extends React.Component<AppMenuMobileProps, AppMenuMobileState> {
  constructor(props: AppMenuMobileProps) {
    super(props);
    
    this.state = new AppMenuMobileState();
    this.clicked = this.clicked.bind(this);
  }

  clicked(e: React.MouseEvent) {
    e.preventDefault();
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  render() {
    const Items = Object
      .keys(Routes)
      .map(x => parseInt(x))
      .filter(x => !Routes[x].parents)
      .filter(x => Routes[x].isMainMenu)
      .map(x => <AppMenuItem key={x} routeID={x}/>);
    return (
      <div className="app-menu-mobile" aria-expanded={this.state.isExpanded}>
          <div className="app-menu-burger" onClick={this.clicked}>
            <div className="app-menu-indicator">
              <span/>
              <span/>
              <span/>
              <span/>
            </div>
            <div className="app-menu-expansion">
              {Items}
            </div>
          </div>
      </div>
    );
  }
}