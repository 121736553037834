import React, { lazy } from 'react';
const HomeContent = lazy(() => import("../pages/HomeContent"));
const ServicesContent = lazy(() => import("../pages/ServicesContent"));
const EngineeringContent = lazy(() => import("../pages/EngineeringContent"));
const ImprovementContent = lazy(() => import("../pages/ImprovementContent"));
const ConsultancyContent = lazy(() => import("../pages/ConsultancyContent"));
const RecruitmentContent = lazy(() => import("../pages/RecruitmentContent"));
// const StoriesContent = lazy(() => import("../pages/StoriesContent"));
// const ConsoleGroupContent = lazy(() => import("../pages/ConsoleGroupContent"));
// const MPowerMSLContent = lazy(() => import("../pages/MPowerMSLContent"));
const ContactContent = lazy(() => import("../pages/ContactContent"));
const PrivacyContent = lazy(() => import("../pages/PrivacyContent"));
const TermsContent = lazy(() => import("../pages/TermsContent"));
const DummyContent = lazy(() => import("../pages/DummyContent"));

export enum RouteID {
    ROUTE_ID_HOME = 1,
    ROUTE_ID_SERVICES,
    ROUTE_ID_ENGINEERING,
    ROUTE_ID_IMPROVEMENT,
    ROUTE_ID_CONSULTANCY,
    ROUTE_ID_RECRUITMENT,
    // ROUTE_ID_STORIES,
    // ROUTE_ID_CONSOLE_GROUP,
    // ROUTE_ID_MPOWER_MSL,
    ROUTE_ID_CONTACT,
    ROUTE_ID_PRIVACY,
    ROUTE_ID_TERMS,
    ROUTE_ID_DUMMY
}

export interface RouteDef {
    path: string,
    pageTitle: string,
    linkTitle: string,
    hash?: string, /*  An anchor on the page */
    control: React.ReactElement, /* A control to render the page */
    parents?: RouteID[]
    isMainMenu?: boolean
}

const Routes: { [id: number]: RouteDef } = {
    [RouteID.ROUTE_ID_HOME]: { 
        path: '/', 
        pageTitle: 'Welcome',
        linkTitle: 'Home',
        isMainMenu: true,
        control: <HomeContent /> },
    [RouteID.ROUTE_ID_SERVICES]: {
        path: '/services',
        pageTitle: 'Services',
        linkTitle: 'Services',
        isMainMenu: true,
        control: <ServicesContent /> },
    [RouteID.ROUTE_ID_ENGINEERING]: {
        path: '/engineering',
        pageTitle: 'Engineering',
        linkTitle: 'Engineering',
        isMainMenu: false,
        control: <EngineeringContent /> },
    [RouteID.ROUTE_ID_IMPROVEMENT]: {
        path: '/improvement',
        pageTitle: 'Improvement',
        linkTitle: 'Improvement',
        isMainMenu: false,
        control: <ImprovementContent /> },
    [RouteID.ROUTE_ID_CONSULTANCY]: {
        path: '/consultancy',
        pageTitle: 'Consultancy',
        linkTitle: 'Consultancy',
        isMainMenu: false,
        control: <ConsultancyContent /> },
    [RouteID.ROUTE_ID_RECRUITMENT]: {
        path: '/recruitment',
        pageTitle: 'Recruitment',
        linkTitle: 'Recruitment',
        isMainMenu: false,
        control: <RecruitmentContent /> },/*
    [RouteID.ROUTE_ID_STORIES]: {
        path: '/stories',
        pageTitle: 'Stories',
        linkTitle: 'Stories',
        isMainMenu: true,
        control: <StoriesContent /> },
    [RouteID.ROUTE_ID_CONSOLE_GROUP]: {
        path: '/console',
        pageTitle: 'Console Group',
        linkTitle: 'Console Group',
        isMainMenu: false,
        control: <ConsoleGroupContent /> },
    [RouteID.ROUTE_ID_MPOWER_MSL]: {
        path: '/mpower',
        pageTitle: 'MPower MSL',
        linkTitle: 'MPower MSL',
        isMainMenu: false,
        control: <MPowerMSLContent /> },*/
    [RouteID.ROUTE_ID_CONTACT]: {
        path: '/contact', 
        pageTitle: 'Contact us',
        linkTitle: 'Contact us',
        isMainMenu: true,
        control: <ContactContent /> },
    [RouteID.ROUTE_ID_PRIVACY]: {
        path: '/privacy',
        pageTitle: 'Privacy Policy',
        linkTitle: 'Privacy Policy',
        control: <PrivacyContent /> },
    [RouteID.ROUTE_ID_TERMS]: {
        path: '/terms',
        pageTitle: 'Terms of Service',
        linkTitle: 'Terms of Service',
        control: <TermsContent /> },
    [RouteID.ROUTE_ID_DUMMY]: {
        path: '/dummy',
        pageTitle: 'Dummy',
        linkTitle: 'Dummy',
        control: <DummyContent /> }
};

export default Routes;