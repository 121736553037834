import React, { Suspense } from 'react';
import LoadingContent from "../pages/LoadingContent";

export interface AppContentProps {
  title: string;
}

const renderLoader = () => <LoadingContent />;

class AppContent extends React.PureComponent<AppContentProps> {
  componentDidMount() {
    document.title = 'Ultima Labs | ' + this.props.title;
  }

  render() {
    return <Suspense fallback={renderLoader()}>{this.props.children}</Suspense>
  }
}

export default AppContent;