import React from 'react';
import './AppBody.scss';

export default class AppBody extends React.PureComponent {
  render() {
    return (
      <div className="app-body">
          {this.props.children}
      </div>
    );
  }
}