import React from 'react';
import './AppBrand.scss';

export default class AppBrand extends React.PureComponent {
  render(): React.ReactElement {
    return (
      <div className="app-brand">
        <p className="brandname">Ultima Labs</p>
        <p className="strapline">A better software every day</p>
      </div>
    );
  }
}