import React from 'react';
import AppLogo from './AppLogo';
import AppBrand from "./AppBrand";
import { AppMenuTablet, AppMenuDesktop, AppMenuMobile } from './AppMenu';
import './AppHeader.scss';

export default class AppHeader extends React.PureComponent {
  render(): React.ReactElement {
    return (
        <header className="app-header">
            <div className="display-flex-row flex-min">
                <AppMenuMobile />
            </div>
            <div className="display-flex-row flex-min">
                <AppLogo />
            </div>
            <div className="display-flex-row flex-auto">
                <AppBrand />
            </div>
            <div className="display-flex-row flex-min">
                <AppMenuTablet />
                <AppMenuDesktop />
            </div>
        </header>
    );
  }
}