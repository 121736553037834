import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Routes from './Routes';
import AppHeader from './AppHeader';
import AppBody from './AppBody';
import AppContent from './AppContent';
import AppFooter from './AppFooter';
import './App.scss';
import { createBrowserHistory } from 'history';

declare global {
  function gtag(...params: any[]): void;
}

export default class App extends React.PureComponent {
  componentDidMount() {
  }

  render() {    
    const history = createBrowserHistory({
      basename: process.env.PUBLIC_URL
    });

    history.listen(function (location) {
      gtag('config', 'UA-151530889-1', { 
        'optimize_id': 'GTM-PV4FZNF',
        'page_title': document.title,
        'page_location': location.pathname + location.search,
        'page_path': location.pathname });
    });

    const RoutesSwitch = Object
      .keys(Routes)
      .map(x => parseInt(x))
      .map(x => <Route key={x} exact path={Routes[x].path}><AppContent title={Routes[x].pageTitle}>{Routes[x].control}</AppContent></Route>);
    return (
      <Router history={history}>
        <div className="app">
          <AppHeader/>
          <AppBody>
            <Switch>
              {RoutesSwitch}
            </Switch>
          </AppBody>
          <AppFooter />
        </div>
      </Router>
    );
  }
}
